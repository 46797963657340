import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`



function SurveillanceSystemsPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Camera Surveillance and Network Video Recorder Technologies"
        description="Video surveillance is an unavoidable necessity for businesses. WYN Technologies provides best-in-class camera networks and video surveillance technology and set-ups. "/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">NVR & Camera Surveillance Systems</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>NVR & Camera Surveillance Systems</GroceryTitle>
              <p>Video surveillance is an unfortunate necessity for all businesses these days. Maintaining a watchful eye over your operation can save you tons of monetary and legal trouble in the long run. Security camera systems have evolved tremendously over the last decade in quality and efficiency to fill that need.</p>

              <p>Advancements in tech have brought a host of features and functions to surveillance systems over the years, including motion sensors, automatic law enforcement contact, and mobile push notification functionality. This technological progress has also opened the doors for better, more efficient recording and storage of footage. Combining all these high-tech developments now allows most consumers, private or otherwise, to have extremely powerful surveillance systems at their fingertips.</p>

              <p><strong>WYN Technologies</strong> stands at the forefront of all these advances, offering customers some of the latest and best NVR security systems available on the market. But where to start? We’re happy to walk you through some of the things you’ll need to know!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Provide With Our NVR & Security Camera Installation Services</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Site Surveys</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Before we ever recommend a solution involving surveillance cameras, we’ll actually start by listening. When you first connect with us, we’ll take some time to learn more about you, your business, your facility & property layout, and what your goals are for video surveillance & security. We’ll also schedule a time to come out and have a look around in person; the best way for us to make observations and gain insights is to see everything with our own eyes. Then we’ll sit down with you to share our findings and make some recommendations about the best surveillance solution for you.</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Quality Product Solutions to Meet Your Budget</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>If you’re like many other business clients we’ve served, you may not have a lot of financial wiggle room when it comes to implementing a video camera surveillance solution. We get it, and we’ll work with you to find the best equipment solution that also suits your budget. We’re happy to source products from top makers like Honeywell, Dahua, Axis, Hikvision, Uniview, and FLIR. And once we land upon the perfect solution that really checks all of the boxes, <strong>WYN Technologies</strong> is your perfect partner for surveillance design, camera system installation, and implementation for your business or organization.</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Product Orientation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p><strong>WYN</strong> is a preferred provider of quality NVR and video camera surveillance system products, but we’re also much more than that. Where we really shine is in our delivery of complete turnkey solutions. By partnering with us, you’ll rest easier knowing that we’ll handle all equipment staging, installation, and configuration. In short, we’ll take care of everything! And we won’t just set it up and then leave you guessing as to how everything works. We take the time to walk you and your key leaders through necessary product orientation, and we’ll share our best-practice tips for operation and utilization, too.</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Ongoing Support When You Need It</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Ever had a commercial technology provider leave you feeling like you had to fend for yourself once they installed your equipment and then walked out the door? We know that can be a pretty unsettling feeling, but you won’t have to worry about that if you choose to partner with <strong>WYN Technologies</strong>! We promise to be here for you whenever you may need us. In addition, we offer some convenient “service after the sale” contracts if you’d like for us to keep everything maintained and in good working order for you. Anytime you have a problem, just give us a call!</p>
                <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link>
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle>
              <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
              <MarkdownContent>
                <h2>Choosing a Video Surveillance System for Your Business</h2>
                <p>Picking a video security system for your business can be a tricky process if you’re unfamiliar with the various types of products out there. And to understand all the bells, whistles, and features of the newest generation of video surveillance systems, you will need to start with precisely what your business will need. No one wants to invest in a system only to discover it’s too much or not enough!</p>
                <p>It is imperative to know what type of cameras are best for your business, as well as how many you will need and what kind of storage system you’ll require. So what are some of the different camera types in today’s market?</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Wireless or Wired Surveillance Systems</h2>
                <p>Choosing whether to set up a surveillance system through a hardline or via a wireless network is the next step to determine what works best for your business. Camera placement is one of the first important things to note. If you want cameras that do not require batteries to operate and don’t mind the added headache of drilling holes and connecting wires, a wired system may be best for you. However, if flexibility is a feature you require, a WiFi-connected camera system might be a better option.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Hidden Cameras</h2>
                <p>If a mounted surveillance system isn’t your cup of tea, you may prefer hidden cameras. These devices are typically small, inconspicuous, and can be placed in everyday objects. This option is excellent for those looking to install a simple security camera in their home or office.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>The Difference Between NVR and DVR</h2>
                <p>The defining difference between an NVR camera system and a DVR is in the first letter of their acronyms. DVR stands for Digital Video Recorder, while NVR represents Network Video Recorder.</p>

                <p>A DVR system works by utilizing analog cameras that can’t process video footage but stream the footage via a coaxial connection to recording devices.</p>

                <p>An NVR surveillance system records and stores video footage directly via a network connection. These surveillance systems work with NVR IP cameras, or cameras directly attached to a network connection. NVR IP camera systems do not have dedicated on-site recording abilities like older security cameras.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>NVR & Surveillance Systems and WYN Technologies</h2>
                <p>Navigating the complexities of surveillance systems can be tricky for the uninitiated. WYN Technologies can help you decide which surveillance system is best for your business! Our experts are committed to providing the best NVR and camera surveillance solutions at affordable prices. Whether you need a dedicated, hard-wired system with multiple cameras for a larger business, or a smaller wireless system for a smaller office space or private residence, we have the answers. Security systems can make all the difference in saving you time, headaches, and money, so give us a call at <a href="tel:3368990555">(336) 899-0555</a>, and let our specialists help you today!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default SurveillanceSystemsPage
